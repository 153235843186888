(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/error-boundary/assets/javascripts/error-boundary.js') >= 0) return;  svs.modules.push('/components/components/error-boundary/assets/javascripts/error-boundary.js');
"use strict";

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const logger = svs.core.log.getLogger('error:error-boundary');
const {
  baseUrl
} = svs.core.data;
class ErrorBoundary extends React.Component {
  constructor() {
    super(...arguments);
    _defineProperty(this, "state", {
      error: undefined
    });
    _defineProperty(this, "logError", function () {
      const stringifyErrors = arg => {
        if (arg instanceof Error) {
          return arg.toString();
        }
        return arg;
      };
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      logger.error(...args.map(stringifyErrors));
    });
    _defineProperty(this, "catch", error => {
      this.logError(error);
      this.setState({
        error
      });
    });
    _defineProperty(this, "retry", () => {
      this.setState({
        error: undefined
      });
    });
    _defineProperty(this, "reload", () => {
      svs.utils.url.navigate(baseUrl);
    });
  }
  static getDerivedStateFromError(error) {
    return {
      error
    };
  }
  componentDidCatch(error, errorInfo) {
    this.logError(error, errorInfo);
  }
  render() {
    const {
      error
    } = this.state;
    const {
      context: ErrorContext,
      children,
      fallback: Fallback,
      reloadOnRetry
    } = this.props;
    if (error) {
      return React.createElement(Fallback, {
        error: error,
        retry: reloadOnRetry ? this.reload : this.retry
      });
    }
    if (ErrorContext) {
      return React.createElement(ErrorContext.Provider, {
        value: {
          catch: this.catch
        }
      }, children);
    }
    return children;
  }
}
ErrorBoundary.defaultProps = {
  fallback: svs.components.errorBoundary.GenericError,
  reloadOnRetry: true
};
setGlobal('svs.components.errorBoundary.ErrorBoundary', ErrorBoundary);

 })(window);