(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/error-boundary/assets/javascripts/generic-error.js') >= 0) return;  svs.modules.push('/components/components/error-boundary/assets/javascripts/generic-error.js');
"use strict";


const {
  Button,
  ReactIcon: Icon
} = svs.ui;
const GenericError = props => {
  const {
    retry
  } = props;
  return React.createElement("div", {
    className: "align-center error-screen padding-3"
  }, React.createElement(Icon, {
    icon: "exclamation-sign",
    size: "900"
  }), React.createElement("h1", {
    className: "f-900 margin-v-1"
  }, "N\xE5got gick fel"), React.createElement("p", {
    className: "f-500 margin-bottom-2"
  }, "Tj\xE4nsten svarar inte p\xE5 grund av ett systemfel."), React.createElement(Button, {
    className: "margin-bottom-1 qa-try-again",
    inverted: true,
    onClick: retry
  }, "F\xF6rs\xF6k igen"));
};
setGlobal('svs.components.errorBoundary.GenericError', GenericError);

 })(window);